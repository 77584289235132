body,
html {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
}

/*
TODO(ENG-2331): to overwrite the default style of the antd date picker, but this will also affect the other date pickers in the app
*/
/* body .ant-picker-panel .ant-picker-footer .ant-picker-today-btn {
  display: none;
} */